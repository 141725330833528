<template>

    <div class="p-grid">
        <div class="p-col-12 p-d-flex p-jc-center">
            <img alt="user header" src="../../../../assets/logo/logo1.png" style="width: 40%;" />
        </div>
        <div class="p-col-12 p-d-flex p-jc-center">
            <h1>Gizlilik Sözleşmesi</h1>
        </div>
        <div class="p-col-12 p-d-flex p-jc-center">
            <div style="font-size: 10px; font-weight: 300; font-style: italic;">Güncelleme Tarihi : 20 Mayıs 2024 </div>
        </div>
        <div class="p-col-12">
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik ("biz", "bizim" veya "bize") gizliliğinizi korumayı taahhüt eder. Bu Gizlilik Politikası,
                kişisel bilgilerinizin Gelsin Teknik tarafından nasıl toplandığını, kullanıldığını ve ifşa edildiğini
                açıklamaktadır.
            </p>
        </div>
        <div class="p-col-12">
            <p>
                &emsp;&emsp;&emsp;
                Bu Gizlilik Politikası, uygulamamız Gelsin Teknik ile birlikte web sitemiz ve ilgili alt alanları
                (topluca "Hizmetimiz") için geçerlidir. Hizmetimize erişerek veya Hizmetimizi kullanarak, bu Gizlilik
                Politikasında ve Hizmet Şartlarımızda açıklandığı şekilde kişisel bilgilerinizi toplamamızı,
                saklamamızı, kullanmamızı ve ifşa etmemizi okuduğunuzu, anladığınızı ve kabul ettiğinizi belirtirsiniz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Tanımlar ve anahtar terimler</b></h2>
            &emsp;&emsp;&emsp;
            Bu Gizlilik Politikasında her şeyi olabildiğince açık bir şekilde açıklamaya yardımcı olmak için, bu
            terimlerden herhangi birine her atıfta bulunulduğunda, kesinlikle şu şekilde tanımlanır:
        </div>
        <div class="p-col-12">
            <ul>
                <li class="p-m-2">Çerez: Bir web sitesi tarafından oluşturulan ve web tarayıcınız tarafından kaydedilen
                    az miktarda veri. Tarayıcınızı tanımlamak, analiz sağlamak, dil tercihiniz veya oturum açma
                    bilgileriniz gibi sizinle ilgili bilgileri hatırlamak için kullanılır.</li>
                <li class="p-m-2">Şirket: Bu politikada "Şirket", "biz", "bize" veya "bizim" ifadeleri kullanıldığında,
                    bu Gizlilik Politikası kapsamındaki bilgilerinizden sorumlu olan Gelsin Teknik'e (Semerciler
                    mahallesi saraçlar sokak yener iş merkezi no:306-307 ADAPAZARI/SAKARYA) atıfta bulunulur.</li>
                <li class="p-m-2">Ülke: Gelsin Teknik'in veya Gelsin Teknik'in sahiplerinin/kurucularının bulunduğu yer,
                    bu durumda TÜRKİYE'dir.</li>
                <li class="p-m-2">Müşteri: Tüketicileriniz veya hizmet kullanıcılarınızla olan ilişkileri yönetmek için
                    Gelsin Teknik Servisini kullanmak üzere kaydolan şirket, kuruluş veya kişiyi ifade eder.</li>
                <li class="p-m-2">Cihaz: Gelsin Teknik'i ziyaret etmek ve hizmetleri kullanmak için kullanılabilecek
                    telefon, tablet, bilgisayar veya diğer herhangi bir cihaz gibi internete bağlı herhangi bir cihaz.
                </li>
                <li class="p-m-2">IP adresi: İnternete bağlı her cihaza İnternet protokolü (IP) adresi olarak bilinen
                    bir numara atanır. Bu numaralar genellikle coğrafi bloklar halinde atanır. Bir IP adresi genellikle
                    bir cihazın İnternet'e bağlandığı konumu tanımlamak için kullanılabilir.</li>
                <li class="p-m-2">Personel: Gelsin Teknik tarafından istihdam edilen veya taraflardan biri adına bir
                    hizmeti yerine getirmek üzere sözleşmeli olan kişileri ifade eder.</li>
                <li class="p-m-2">Kişisel Veriler: Doğrudan, dolaylı olarak veya diğer bilgilerle bağlantılı olarak -
                    kişisel kimlik numarası da dahil olmak üzere - gerçek bir kişinin tanımlanmasına veya
                    tanımlanabilirliğine izin veren herhangi bir bilgi.</li>
                <li class="p-m-2">Hizmet: Gelsin Teknik tarafından ilgili şartlarda (varsa) ve bu platformda açıklandığı
                    şekilde sağlanan hizmeti ifade eder. </li>
                <li class="p-m-2">Üçüncü taraf hizmeti: İçeriğimizi sağlayan veya ürün veya hizmetlerinin ilginizi
                    çekebileceğini düşündüğümüz reklamcıları, yarışma sponsorlarını, promosyon ve pazarlama ortaklarını
                    ve diğer kişileri ifade eder.</li>
                <li class="p-m-2">Web Sitesi: Gelsin Teknik'in şu URL üzerinden erişilebilen sitesi:
                    www.gelsinteknik.com</li>
                <li class="p-m-2">Siz: Hizmetleri kullanmak için Gelsin Teknik'e kayıtlı olan kişi veya kuruluş.</li>
            </ul>
        </div>

        <div class="p-col-12">
            <h2><b>Hangi Bilgileri Topluyoruz?</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Web sitemizi ziyaret ettiğinizde, sitemize kaydolduğunuzda, sipariş verdiğinizde, bültenimize abone
                olduğunuzda, bir ankete yanıt verdiğinizde veya bir form doldurduğunuzda sizden bilgi topluyoruz.
            </p>
        </div>

        <div class="p-col-12">
            <ul>
                <li class="p-m-2">İsim / Kullanıcı Adı</li>
                <li class="p-m-2">Telefon Numaraları</li>
                <li class="p-m-2">E-posta Adresleri</li>
                <li class="p-m-2">Posta Adresleri</li>
                <li class="p-m-2">Fatura Adresleri</li>
            </ul>
        </div>
        <div class="p-col-12">
            <p>
                &emsp;&emsp;&emsp;
                Daha iyi bir kullanıcı deneyimi için mobil cihazlardan da bilgi topluyoruz, ancak bu özellikler tamamen
                isteğe bağlıdır:
            </p>
            <ul>
                <li class="p-m-2">Konum (GPS): Konum verileri, ilgi alanlarınızın doğru bir temsilini oluşturmanıza
                    yardımcı olur ve bu, potansiyel müşterilere daha hedefli ve alakalı reklamlar sunmak için
                    kullanılabilir.</li>
            </ul>
        </div>
        <div class="p-col-12">
            <h2><b>Gelsin Teknik, üçüncü taraflardan alınan son kullanıcı bilgilerini ne zaman kullanır?</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik, müşterilerimize Gelsin Teknik hizmetlerini sağlamak için gerekli olan Son Kullanıcı
                Verilerini toplayacaktır. Son kullanıcılar, sosyal medya web sitelerinde kullanıma sundukları bilgileri
                bize gönüllü olarak sağlayabilirler. Bize bu tür bilgileri verirseniz, belirttiğiniz sosyal medya web
                sitelerinden kamuya açık bilgiler toplayabiliriz. Sosyal medya web sitelerinin, bu web sitelerini
                ziyaret ederek ve gizlilik ayarlarınızı değiştirerek bilgilerinizin ne kadarını herkese açık hale
                getirdiğini kontrol edebilirsiniz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Gelsin Teknik, üçüncü taraflardan alınan müşteri bilgilerini ne zaman kullanır?</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Bizimle iletişime geçtiğinizde üçüncü taraflardan bazı bilgiler alırız. Örneğin, Gelsin Teknik müşterisi
                olmakla ilgilendiğinizi göstermek için e-posta adresinizi bize gönderdiğinizde, Gelsin Teknik'e otomatik
                dolandırıcılık tespit hizmetleri sağlayan üçüncü bir taraftan bilgi alırız. Ayrıca zaman zaman sosyal
                medya web sitelerinde herkese açık hale getirilen bilgileri de topluyoruz. Sosyal medya web sitelerinin,
                bu web sitelerini ziyaret ederek ve gizlilik ayarlarınızı değiştirerek bilgilerinizin ne kadarını
                herkese açık hale getirdiğini kontrol edebilirsiniz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Topladığımız bilgileri üçüncü taraflarla paylaşıyor muyuz?</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Hem kişisel hem de kişisel olmayan topladığımız bilgileri, reklamcılar, yarışma sponsorları, promosyon
                ve pazarlama ortakları ve içeriğimizi sağlayan veya ürün veya hizmetlerinin ilginizi çekebileceğini
                düşündüğümüz diğer kişiler gibi üçüncü taraflarla paylaşabiliriz. Ayrıca, mevcut ve gelecekteki bağlı
                şirketlerimiz ve iş ortaklarımızla da paylaşabiliriz ve bir birleşme, varlık satışı veya başka bir
                ticari yeniden yapılanmaya dahil olursak, kişisel ve kişisel olmayan bilgilerinizi ilgili haleflerimizle
                paylaşabilir veya aktarabiliriz.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Sunucularımızı ve web sitesini barındırma ve bakım, veritabanı depolama ve yönetimi, e-posta yönetimi,
                depolama pazarlaması, kredi kartı işlemleri, müşteri hizmetleri ve web sitesi aracılığıyla satın
                alabileceğiniz ürün ve hizmetler için siparişleri yerine getirme gibi işlevleri yerine getirmek ve bize
                hizmet sağlamak için güvenilir üçüncü taraf hizmet sağlayıcılarla çalışabiliriz. Kişisel bilgilerinizi
                ve muhtemelen bazı kişisel olmayan bilgilerinizi, bu hizmetleri bizim için ve sizin için
                gerçekleştirmelerini sağlamak için bu üçüncü taraflarla paylaşacağız.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                IP adresleri de dahil olmak üzere günlük dosyası verilerimizin bir kısmını, web analizi ortakları,
                uygulama geliştiricileri ve reklam ağları gibi üçüncü taraflarla analiz amacıyla paylaşabiliriz. IP
                adresiniz paylaşılıyorsa, genel konumu ve bağlantı hızı, web sitesini paylaşılan bir konumda ziyaret
                edip etmediğiniz ve web sitesini ziyaret etmek için kullanılan cihazın türü gibi diğer teknolojik
                bilgileri tahmin etmek için kullanılabilir. Reklamlarımız ve web sitesinde gördükleriniz hakkında bilgi
                toplayabilir ve ardından bizim ve reklamverenlerimiz için denetim, araştırma ve raporlama
                sağlayabilirler.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Hakkınızdaki kişisel ve kişisel olmayan bilgileri, tamamen kendi takdirimize bağlı olarak, taleplere,
                yasal süreçlere (mahkeme celpleri dahil) yanıt vermek, haklarımızı ve çıkarlarımızı veya üçüncü bir
                tarafın haklarını, kamunun veya herhangi bir kişinin güvenliğini korumak için gerekli veya uygun
                olduğuna inandığımız durumlarda hükümet veya kolluk kuvvetlerine veya özel taraflara ifşa edebiliriz.
                yasa dışı, etik olmayan veya yasal olarak dava edilebilir herhangi bir faaliyeti önlemek veya durdurmak
                veya geçerli mahkeme emirlerine, yasalara, kurallara ve düzenlemelere başka bir şekilde uymak.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Müşterilerden ve son kullanıcılardan bilgiler nerede ve ne zaman toplanır?</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik, bize gönderdiğiniz kişisel bilgileri toplayacaktır. Sizinle ilgili kişisel bilgileri
                yukarıda açıklandığı gibi üçüncü taraflardan da alabiliriz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Topladığımız Bilgileri Nasıl Kullanıyoruz?</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Sizden topladığımız bilgilerden herhangi biri aşağıdaki yollardan biriyle kullanılabilir:
            </p>
            <ul>
                <li class="p-m-2">Deneyiminizi kişiselleştirmek için (bilgileriniz bireysel ihtiyaçlarınıza daha iyi
                    yanıt vermemize yardımcı olur)</li>
                <li class="p-m-2">Web sitemizi geliştirmek için (sizden aldığımız bilgi ve geri bildirimlere dayanarak
                    web sitesi tekliflerimizi sürekli olarak iyileştirmeye çalışıyoruz)</li>
                <li class="p-m-2">Müşteri hizmetlerini iyileştirmek için (bilgileriniz, müşteri hizmetleri taleplerinize
                    ve destek ihtiyaçlarınıza daha etkili bir şekilde yanıt vermemize yardımcı olur)</li>
                <li class="p-m-2">İşlemleri işlemek için</li>
                <li class="p-m-2">Bir yarışmayı, promosyonu, anketi veya başka bir site özelliğini yönetmek için</li>
                <li class="p-m-2">Periyodik e-postalar göndermek için</li>
            </ul>
        </div>
        <div class="p-col-12">
            <h2><b>E-posta Adresinizi Nasıl Kullanıyoruz?</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Bu web sitesine e-posta adresinizi göndererek, bizden e-posta almayı kabul etmiş olursunuz. Bu e-posta
                listelerinden herhangi birine katılımınızı, ilgili e-postada yer alan devre dışı bırakma bağlantısına
                veya diğer abonelikten çıkma seçeneğine tıklayarak istediğiniz zaman iptal edebilirsiniz. Yalnızca
                doğrudan veya üçüncü bir taraf aracılığıyla kendileriyle iletişim kurmamıza izin veren kişilere e-posta
                göndeririz. İstenmeyen ticari e-postalar göndermiyoruz, çünkü spam'den sizin kadar nefret ediyoruz.
                E-posta adresinizi göndererek, e-posta adresinizi, bizden iletişim almayı seçen belirli kişilere özel
                reklamlar gösterdiğimiz Facebook gibi sitelerde müşteri kitlesini hedeflemek için kullanmamıza izin
                vermeyi de kabul etmiş olursunuz. Yalnızca sipariş işleme sayfası aracılığıyla gönderilen e-posta
                adresleri, yalnızca size siparişinizle ilgili bilgi ve güncellemeleri göndermek amacıyla
                kullanılacaktır. Bununla birlikte, aynı e-postayı bize başka bir yöntemle sağladıysanız, bu Politikada
                belirtilen amaçlardan herhangi biri için kullanabiliriz. Not: Herhangi bir zamanda gelecekteki
                e-postaları almaktan çıkmak isterseniz, her e-postanın altına ayrıntılı abonelikten çıkma talimatları
                ekleriz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Bilgilerinizi ne kadar süreyle saklıyoruz?</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Bilgilerinizi yalnızca Gelsin Teknik'i size sağlamak ve bu politikada açıklanan amaçları yerine getirmek
                için ihtiyaç duyduğumuz sürece saklarız. Bu, bilgilerinizi paylaştığımız ve bizim adımıza hizmet yürüten
                herkes için de geçerlidir. Bilgilerinizi artık kullanmamız gerekmediğinde ve yasal veya düzenleyici
                yükümlülüklerimize uymak için saklamamıza gerek kalmadığında, sizi tanımlayamamamız için ya
                sistemlerimizden kaldıracağız ya da kişiselleştireceğiz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Bilgilerinizi Nasıl Koruyoruz?</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Bir sipariş verdiğinizde veya kişisel bilgilerinizi girdiğinizde, gönderdiğinizde veya bunlara
                eriştiğinizde kişisel bilgilerinizin güvenliğini sağlamak için çeşitli güvenlik önlemleri uyguluyoruz.
                Güvenli bir sunucu kullanımı sunuyoruz. Sağlanan tüm hassas/kredi bilgileri, Güvenli Yuva Katmanı (SSL)
                teknolojisi aracılığıyla iletilir ve daha sonra yalnızca bu tür sistemlere özel erişim haklarına sahip
                yetkili kişiler tarafından erişilebilmesi için Ödeme ağ geçidi sağlayıcılarımızın veritabanına
                şifrelenir ve bilgilerin gizli tutulması gerekir. Bir işlemden sonra özel bilgileriniz (kredi kartları,
                sosyal güvenlik numaraları, finansal bilgiler vb.) asla dosyada tutulmaz. Bununla birlikte, Gelsin
                Teknik'e ilettiğiniz herhangi bir bilginin mutlak güvenliğini garanti edemeyiz veya garanti edemeyiz
                veya Hizmetteki bilgilerinize fiziksel, teknik veya yönetimsel korumalarımızdan herhangi birinin ihlali
                nedeniyle erişilemeyeceğini, ifşa edilemeyeceğini, değiştirilemeyeceğini veya imha edilemeyeceğini
                garanti edemeyiz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Bilgilerim başka ülkelere aktarılabilir mi?</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik, Türkiye'de kurulmuştur. Web sitemiz aracılığıyla, sizinle doğrudan etkileşimler yoluyla
                veya yardım hizmetlerimizin kullanımından toplanan bilgiler, zaman zaman ofislerimize veya personelimize
                veya dünyanın her yerinde bulunan üçüncü taraflara aktarılabilir ve bu tür verilerin kullanımını ve
                aktarımını düzenleyen genel uygulanabilirlik yasalarına sahip olmayabilecek ülkeler de dahil olmak üzere
                dünyanın herhangi bir yerinde görüntülenebilir ve barındırılabilir. Yürürlükteki yasaların izin verdiği
                en geniş ölçüde, yukarıdakilerden herhangi birini kullanarak, bu tür bilgilerin sınır ötesi aktarımına
                ve barındırılmasına gönüllü olarak izin vermiş olursunuz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Gelsin Teknik Servisi aracılığıyla toplanan bilgiler güvenli midir?</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Bilgilerinizin güvenliğini korumak için önlemler alıyoruz. Bilgilerinizin korunmasına, yetkisiz erişimin
                önlenmesine, veri güvenliğinin sağlanmasına ve bilgilerinizin doğru şekilde kullanılmasına yardımcı
                olacak fiziksel, elektronik ve yönetimsel prosedürlerimiz vardır. Ancak, şifreleme sistemleri de dahil
                olmak üzere ne insanlar ne de güvenlik sistemleri kusursuz değildir. Ayrıca, insanlar kasıtlı suçlar
                işleyebilir, hata yapabilir veya politikalara uymayabilir. Bu nedenle, kişisel bilgilerinizi korumak
                için makul çabayı göstersek de, mutlak güvenliğini garanti edemeyiz. Yürürlükteki yasalar kişisel
                bilgilerinizi korumak için reddedilemez bir görev yüklüyorsa, kasıtlı suistimalin bu göreve
                uygunluğumuzu ölçmek için kullanılan standartlar olacağını kabul edersiniz
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Bilgilerimi güncelleyebilir veya düzeltebilir miyim?</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik'in topladığı bilgilerde güncelleme veya düzeltme talep etme haklarınız, Gelsin Teknik ile
                olan ilişkinize bağlıdır. Personel, şirket içi istihdam politikalarımızda ayrıntılı olarak açıklandığı
                şekilde bilgilerini güncelleyebilir veya düzeltebilir.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Müşteriler, kişisel olarak tanımlanabilir bilgilerin belirli kullanımlarının ve ifşalarının aşağıdaki
                şekilde kısıtlanmasını talep etme hakkına sahiptir. Hesabınızı iptal ederek (1) kişisel olarak
                tanımlanabilir bilgilerinizi güncellemek veya düzeltmek, (2) bizden aldığınız iletişimler ve diğer
                bilgilerle ilgili tercihlerinizi değiştirmek veya (3) sistemlerimizde hakkınızda tutulan kişisel olarak
                tanımlanabilir bilgileri silmek (aşağıdaki paragrafa tabi olarak) için bizimle iletişime geçebilirsiniz.
                Bu tür güncellemelerin, düzeltmelerin, değişikliklerin ve silmelerin, sakladığımız diğer bilgiler veya
                bu tür bir güncelleme, düzeltme, değişiklik veya silme işleminden önce bu Gizlilik Politikasına uygun
                olarak üçüncü taraflara sağladığımız bilgiler üzerinde hiçbir etkisi olmayacaktır. Gizliliğinizi ve
                güvenliğinizi korumak için, size profil erişimi vermeden veya düzeltmeler yapmadan önce kimliğinizi
                doğrulamak için makul adımlar atabiliriz (benzersiz bir şifre istemek gibi). Benzersiz şifrenizin ve
                hesap bilgilerinizin gizliliğini her zaman korumaktan siz sorumlusunuz.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Bize vermiş olduğunuz bilgilerin her bir kaydının sistemimizden kaldırılmasının teknolojik olarak mümkün
                olmadığını bilmelisiniz. Bilgileri kasıtsız kayıplardan korumak için sistemlerimizi yedekleme ihtiyacı,
                bilgilerinizin bir kopyasının silinemez bir biçimde bulunabileceği ve bulmamızın zor veya imkansız
                olacağı anlamına gelir. Talebinizi aldıktan hemen sonra, aktif olarak kullandığımız veritabanlarında ve
                diğer kolayca aranabilir ortamlarda saklanan tüm kişisel bilgiler, makul ve teknik olarak uygulanabilir
                olan en kısa sürede ve ölçüde güncellenecek, düzeltilecek, değiştirilecek veya silinecektir.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Son kullanıcıysanız ve hakkınızda sahip olduğumuz herhangi bir bilgiyi güncellemek, silmek veya almak
                istiyorsanız, bunu müşterisi olduğunuz kuruluşla iletişime geçerek yapabilirsiniz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Personel</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik çalışanı veya başvuru sahibiyseniz, bize gönüllü olarak verdiğiniz bilgileri topluyoruz.
                Toplanan bilgileri, çalışanlara sağlanan faydaları yönetmek ve başvuru sahiplerini taramak için İnsan
                Kaynakları amaçları için kullanırız.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                (1) Bilgilerinizi güncellemek veya düzeltmek, (2) bizden aldığınız iletişimler ve diğer bilgilerle
                ilgili tercihlerinizi değiştirmek veya (3) sizinle ilgili sahip olduğumuz bilgilerin bir kaydını almak
                için bizimle iletişime geçebilirsiniz. Bu tür güncellemelerin, düzeltmelerin, değişikliklerin ve
                silmelerin, sakladığımız diğer bilgiler veya bu tür bir güncelleme, düzeltme, değişiklik veya silme
                işleminden önce bu Gizlilik Politikasına uygun olarak üçüncü taraflara sağladığımız bilgiler üzerinde
                hiçbir etkisi olmayacaktır.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>İş Satışı</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik'in veya Kurumsal İştiraklerinden herhangi birinin (burada tanımlandığı şekilde)
                varlıklarının tamamının veya önemli bir kısmının veya Gelsin Teknik'in veya Hizmetin ilgili olduğu
                Kurumsal İştiraklerinden herhangi birinin varlığının tamamının veya önemli bir kısmının satışı,
                birleşmesi veya başka bir şekilde devredilmesi durumunda bilgileri üçüncü bir tarafa aktarma hakkımızı
                saklı tutarız. veya üçüncü tarafın bu Gizlilik Politikasının şartlarına uymayı kabul etmesi koşuluyla,
                işimizi durdurmamız veya bir dilekçe vermemiz veya iflas, yeniden yapılanma veya benzeri işlemlerde bize
                karşı bir dilekçe vermemiz durumunda.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Bağlı</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Sizinle ilgili bilgileri (kişisel bilgiler dahil) Kurumsal İştiraklerimize ifşa edebiliriz. Bu Gizlilik
                Politikasının amaçları doğrultusunda, "Kurumsal İştirak", Gelsin Teknik'i doğrudan veya dolaylı olarak
                kontrol eden, Gelsin Teknik tarafından kontrol edilen veya Gelsin Teknik ile ortak kontrol altında olan
                herhangi bir kişi veya kuruluş anlamına gelir. Kurumsal İştiraklerimize sağladığımız sizinle ilgili her
                türlü bilgi, bu Kurumsal İştirakler tarafından bu Gizlilik Politikasının şartlarına uygun olarak ele
                alınacaktır.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Geçerli Kanun</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Bu Gizlilik Politikası, kanunlar ihtilafı hükümlerine bakılmaksızın Türkiye kanunlarına tabidir.
                Gizlilik Kalkanı veya İsviçre-ABD çerçevesi kapsamında talepte bulunma hakkına sahip olabilecek kişiler
                dışında, bu Gizlilik Politikası kapsamında veya bu Gizlilik Politikası ile bağlantılı olarak taraflar
                arasında ortaya çıkan herhangi bir dava veya anlaşmazlıkla bağlantılı olarak mahkemelerin münhasır yargı
                yetkisini kabul etmektesiniz.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Bu Sözleşme, kanunlar ihtilafı kuralları hariç olmak üzere, Türkiye kanunlarına ve web sitesini
                kullanımınıza tabi olacaktır. Web sitesini kullanımınız diğer yerel, eyalet, ulusal veya uluslararası
                yasalara da tabi olabilir.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik'i kullanarak veya doğrudan bizimle iletişime geçerek, bu Gizlilik Politikasını kabul
                ettiğinizi belirtmiş olursunuz. Bu Gizlilik Politikasını kabul etmiyorsanız, web sitemizle etkileşime
                girmemeli veya hizmetlerimizi kullanmamalısınız. Web sitesini kullanmaya devam etmeniz, bizimle doğrudan
                etkileşim kurmanız veya bu Gizlilik Politikasında kişisel bilgilerinizin kullanımını veya ifşasını
                önemli ölçüde etkilemeyen değişikliklerin yayınlanmasının ardından, bu değişiklikleri kabul ettiğiniz
                anlamına gelecektir.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Sizin Onayınız</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Sitemizi ziyaret ettiğinizde nelerin ayarlandığı ve nasıl kullanıldığı konusunda size tam şeffaflık
                sağlamak için Gizlilik Politikamızı güncelledik. Gelsin Teknik'imizi kullanarak, bir hesap açarak veya
                bir satın alma işlemi yaparak, Gizlilik Politikamızı kabul etmiş ve şartlarını kabul etmiş olursunuz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Diğer Web Sitelerine Bağlantılar</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Bu Gizlilik Politikası yalnızca Hizmetler için geçerlidir. Hizmetler, Gelsin Teknik tarafından
                işletilmeyen veya kontrol edilmeyen diğer web sitelerine bağlantılar içerebilir. Bu tür web sitelerinde
                ifade edilen içerik, doğruluk veya görüşlerden sorumlu değiliz ve bu tür web siteleri tarafımızca
                araştırılmaz, izlenmez veya doğruluk veya eksiksizlik açısından kontrol edilmez. Hizmetlerden başka bir
                web sitesine gitmek için bir bağlantı kullandığınızda, Gizlilik Politikamızın artık yürürlükte
                olmadığını lütfen unutmayın. Platformumuzda bağlantısı olanlar da dahil olmak üzere başka herhangi bir
                web sitesinde gezinmeniz ve etkileşiminiz, o web sitesinin kendi kurallarına ve politikalarına tabidir.
                Bu tür üçüncü taraflar, hakkınızda bilgi toplamak için kendi çerezlerini veya diğer yöntemlerini
                kullanabilir.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Çerezler</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik, web sitemizin ziyaret ettiğiniz alanlarını tanımlamak için "Çerezler" kullanır. Çerez,
                web tarayıcınız tarafından bilgisayarınızda veya mobil cihazınızda depolanan küçük bir veri parçasıdır.
                Çerezleri, web sitemizin performansını ve işlevselliğini geliştirmek için kullanıyoruz, ancak
                kullanımları için gerekli değildir. Ancak, bu çerezler olmadan, videolar gibi belirli işlevler
                kullanılamayabilir veya daha önce oturum açtığınızı hatırlayamayacağımız için web sitesini her ziyaret
                ettiğinizde oturum açma bilgilerinizi girmeniz gerekebilir. Çoğu web tarayıcısı, Çerezlerin kullanımını
                devre dışı bırakacak şekilde ayarlanabilir. Ancak, Çerezleri devre dışı bırakırsanız, web sitemizdeki
                işlevlere doğru veya hiç erişemeyebilirsiniz. Kişisel Olarak Tanımlanabilir Bilgileri asla Çerezlere
                yerleştirmeyiz
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Çerezleri ve benzer teknolojileri engelleme ve devre dışı bırakma</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Nerede olursanız olun, tarayıcınızı çerezleri ve benzer teknolojileri engelleyecek şekilde de
                ayarlayabilirsiniz, ancak bu işlem temel çerezlerimizi engelleyebilir ve web sitemizin düzgün
                çalışmasını engelleyebilir ve tüm özelliklerinden ve hizmetlerinden tam olarak yararlanamayabilirsiniz.
                Ayrıca, tarayıcınızda çerezleri engellerseniz, bazı kayıtlı bilgileri (örn. kayıtlı oturum açma
                bilgileri, site tercihleri) kaybedebileceğinizi de bilmelisiniz. Farklı tarayıcılar size farklı
                denetimler sunar. Bir çerezi veya çerez kategorisini devre dışı bırakmak, çerezi tarayıcınızdan silmez,
                bunu tarayıcınızın içinden kendiniz yapmanız gerekir, daha fazla bilgi için tarayıcınızın yardım
                menüsünü ziyaret etmelisiniz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Ödeme detayları</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Bize sağladığınız herhangi bir kredi kartı veya diğer ödeme işleme ayrıntılarıyla ilgili olarak, bu
                gizli bilgilerin mümkün olan en güvenli şekilde saklanacağını taahhüt ediyoruz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Çocukların Gizliliği</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                13 yaşın altındaki hiç kimseye hitap etmiyoruz. 13 yaşın altındaki hiç kimseden bilerek kişisel olarak
                tanımlanabilir bilgiler toplamıyoruz. Bir ebeveyn veya vasi iseniz ve çocuğunuzun Bize Kişisel Veriler
                sağladığının farkındaysanız, lütfen Bizimle iletişime geçin. Ebeveyn onayını doğrulamadan 13 yaşın
                altındaki herhangi birinden Kişisel Veri topladığımızı fark edersek, bu bilgileri sunucularımızdan
                kaldırmak için adımlar atarız.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Gizlilik Politikamızdaki Değişiklikler</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Hizmetimizi ve politikalarımızı değiştirebiliriz ve Hizmetimizi ve politikalarımızı doğru bir şekilde
                yansıtmaları için bu Gizlilik Politikasında değişiklikler yapmamız gerekebilir. Yasalar aksini
                gerektirmedikçe, bu Gizlilik Politikasında değişiklik yapmadan önce sizi bilgilendireceğiz (örneğin,
                Hizmetimiz aracılığıyla) ve yürürlüğe girmeden önce bunları gözden geçirme fırsatı vereceğiz. Ardından,
                Hizmeti kullanmaya devam ederseniz, güncellenmiş Gizlilik Politikasına bağlı kalacaksınız. Bunu veya
                güncellenmiş herhangi bir Gizlilik Politikasını kabul etmek istemiyorsanız, hesabınızı silebilirsiniz.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Üçüncü Taraf Hizmetleri</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Üçüncü taraf içeriğini (veriler, bilgiler, uygulamalar ve diğer ürün hizmetleri dahil) görüntüleyebilir,
                dahil edebilir veya kullanıma sunabilir veya üçüncü taraf web sitelerine veya hizmetlerine ("Üçüncü
                Taraf Hizmetleri") bağlantılar sağlayabiliriz.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Gelsin Teknik'in doğruluğu, eksiksizliği, güncelliği, geçerliliği, telif haklarına uygunluğu, yasallığı,
                dürüstlüğü, kalitesi veya diğer herhangi bir yönü dahil olmak üzere herhangi bir Üçüncü Taraf
                Hizmetinden sorumlu olmayacağını kabul ve beyan etmektesiniz. Gelsin Teknik, herhangi bir Üçüncü Taraf
                Hizmeti için size veya başka herhangi bir kişi veya kuruluşa karşı herhangi bir yükümlülük veya
                sorumluluk kabul etmez ve olmayacaktır.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Üçüncü Taraf Hizmetleri ve bunlara bağlantılar yalnızca size kolaylık sağlamak amacıyla sağlanır ve
                bunlara tamamen kendi sorumluluğunuzda ve bu tür üçüncü tarafların hüküm ve koşullarına tabi olarak
                erişir ve kullanırsınız.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Takip Teknolojileri</b></h2>
            <ul>
                <li class="p-m-2">Google Haritalar API'ları
                    <ul>
                        <li class="p-m-2">Google Haritalar API'sı, özel bir harita, aranabilir bir harita, check-in
                            işlevleri oluşturmak, konumla senkronize canlı verileri görüntülemek, rotaları planlamak
                            veya yalnızca birkaçını saymak gerekirse bir karma oluşturmak için kullanılabilecek sağlam
                            bir araçtır.</li>
                        <li class="p-m-2">Google Haritalar API, güvenlik amacıyla Sizden ve Cihazınızdan bilgi
                            toplayabilir.</li>
                        <li class="p-m-2">Google Haritalar API'sı, Gizlilik Politikası'na uygun olarak tutulan bilgileri
                            toplar.</li>
                    </ul>
                </li>
                <li class="p-m-2">Çerezler
                    <ul>
                        <p>Platformumuzun performansını ve işlevselliğini geliştirmek için Çerezler kullanıyoruz, ancak
                            kullanımları için gerekli değildir. Ancak, bu çerezler olmadan, videolar gibi belirli
                            işlevler kullanılamayabilir veya daha önce oturum açtığınızı hatırlayamayacağımız için
                            platformu her ziyaret ettiğinizde oturum açma bilgilerinizi girmeniz gerekebilir.</p>
                    </ul>
                </li>
            </ul>
        </div>
        <div class="p-col-12">
            <h2><b>Genel Veri Koruma Yönetmeliği (GDPR) hakkında bilgi</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Avrupa Ekonomik Alanı'ndan (AEA) iseniz sizden bilgi topluyor ve kullanıyor olabiliriz ve Gizlilik
                Politikamızın bu bölümünde bu verilerin tam olarak nasıl ve neden toplandığını ve bu verileri
                çoğaltılmaya veya yanlış şekilde kullanılmaya karşı nasıl koruduğumuzu açıklayacağız.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>GDPR nedir?</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                GDPR, AB'de ikamet edenlerin verilerinin şirketler tarafından nasıl korunduğunu düzenleyen ve AB'de
                ikamet edenlerin kişisel verileri üzerindeki kontrolünü artıran, AB çapında bir gizlilik ve veri koruma
                yasasıdır.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                GDPR, yalnızca AB merkezli işletmeler ve AB'de ikamet edenler için değil, küresel olarak faaliyet
                gösteren tüm şirketler için geçerlidir. Müşterilerimizin verileri, nerede bulunduklarına bakılmaksızın
                önemlidir, bu nedenle GDPR kontrollerini dünya çapındaki tüm operasyonlarımız için temel standardımız
                olarak uyguladık.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Kişisel veri nedir?</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Kimliği belirlenebilir veya kimliği belirlenebilir bir bireyle ilgili her türlü veri. GDPR, bir kişiyi
                tanımlamak için tek başına veya diğer bilgi parçalarıyla birlikte kullanılabilecek geniş bir bilgi
                yelpazesini kapsar. Kişisel veriler, bir kişinin adının veya e-posta adresinin ötesine geçer. Bazı
                örnekler arasında finansal bilgiler, siyasi görüşler, genetik veriler, biyometrik veriler, IP adresleri,
                fiziksel adres, cinsel yönelim ve etnik köken sayılabilir.
            </p>
            <p>
                &emsp;&emsp;&emsp;Veri Koruma İlkeleri aşağıdaki gibi gereksinimleri içerir:
            </p>
            <ul>
                <li class="p-m-2">Toplanan kişisel veriler adil, yasal ve şeffaf bir şekilde işlenmeli ve yalnızca bir
                    kişinin makul olarak bekleyeceği şekilde kullanılmalıdır.</li>
                <li class="p-m-2">Kişisel veriler yalnızca belirli bir amacı yerine getirmek için toplanmalı ve yalnızca
                    bu amaç için kullanılmalıdır. Kuruluşlar, kişisel verileri toplarken neden ihtiyaç duyduklarını
                    belirtmelidir.</li>
                <li class="p-m-2">Kişisel veriler, amacını yerine getirmek için gerekenden daha uzun süre
                    tutulmamalıdır.</li>
                <li class="p-m-2">GDPR kapsamındaki kişiler kendi kişisel verilerine erişme hakkına sahiptir. Ayrıca
                    verilerinin bir kopyasını isteyebilir ve verilerinin güncelleştirilmesini, silinmesini,
                    kısıtlanmasını veya başka bir kuruluşa taşınmasını isteyebilirler.</li>
            </ul>
        </div>
        <div class="p-col-12">
            <h2><b>GDPR neden önemlidir?</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                GDPR, şirketlerin bireylerin topladıkları ve işledikleri kişisel verilerini nasıl korumaları gerektiğine
                ilişkin bazı yeni gereksinimler ekler. Ayrıca, yaptırımı artırarak ve ihlal için daha büyük para
                cezaları uygulayarak uyum için riskleri artırır. Bu gerçeklerin ötesinde, yapılacak doğru şey budur.
                Gelsin Teknik olarak, veri gizliliğinizin çok önemli olduğuna inanıyoruz ve bu yeni düzenlemenin
                gerekliliklerinin ötesine geçen sağlam güvenlik ve gizlilik uygulamalarımız zaten var.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Bireysel Veri Sahibinin Hakları - Veri Erişimi, Taşınabilirliği ve Silinmesi</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Müşterilerimizin GDPR'nin veri sahibi hakları gereksinimlerini karşılamalarına yardımcı olmayı taahhüt
                ediyoruz. Gelsin Teknik, tüm kişisel verileri tam olarak incelenmiş, DPA uyumlu satıcılarda işler veya
                saklar. Hesabınız silinmediği sürece tüm konuşma ve kişisel verileri 6 yıla kadar saklarız. Bu durumda,
                tüm verileri Hizmet Şartlarımıza ve Gizlilik Politikamıza uygun olarak imha ederiz, ancak 60 günden
                fazla tutmayız.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                AB müşterileriyle çalışıyorsanız, onlara kişisel verilere erişme, bunları güncelleme, alma ve kaldırma
                olanağı sağlayabilmeniz gerektiğinin farkındayız. Seni yakaladık! En başından beri self servis olarak
                kurulduk ve verilerinize ve müşterilerinizin verilerine her zaman erişmenizi sağladık. Müşteri destek
                ekibimiz, API ile çalışmayla ilgili tüm sorularınızı yanıtlamak için burada.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Kaliforniya'da İkamet Edenler</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                Kaliforniya Tüketici Gizliliği Yasası (CCPA), topladığımız Kişisel Bilgi kategorilerini ve bunları nasıl
                kullandığımızı, Kişisel Bilgileri topladığımız kaynak kategorilerini ve bunları paylaştığımız üçüncü
                tarafları yukarıda açıkladığımız şekilde açıklamamızı gerektirir.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Ayrıca, Kaliforniya'da ikamet edenlerin Kaliforniya yasaları kapsamında sahip olduğu haklar hakkında
                bilgi vermemiz gerekmektedir. Aşağıdaki hakları kullanabilirsiniz:
            </p>
            <ul>
                <li class="p-m-2">Bilme ve Erişim Hakkı. Aşağıdakilerle ilgili doğrulanabilir bir bilgi talebi
                    gönderebilirsiniz: (1) topladığımız, kullandığımız veya paylaştığımız Kişisel Bilgi kategorileri;
                    (2) Kişisel Bilgi kategorilerinin tarafımızca toplanma veya kullanılma amaçları; (3) Kişisel
                    Bilgileri topladığımız kaynak kategorileri; ve (4) hakkınızda topladığımız belirli Kişisel Bilgi
                    parçaları.</li>
                <li class="p-m-2">Eşit Hizmet Hakkı. Gizlilik haklarınızı kullanmanız durumunda size karşı ayrımcılık
                    yapmayacağız.</li>
                <li class="p-m-2">Silme Hakkı. Hesabınızı kapatmak için doğrulanabilir bir talep gönderebilirsiniz ve
                    hakkınızda topladığımız Kişisel Bilgileri sileceğiz.</li>
                <li class="p-m-2">Tüketicinin kişisel verilerini satan bir işletmenin, tüketicinin kişisel verilerini
                    satmamasını talep edin.</li>
            </ul>
            <p>
                &emsp;&emsp;&emsp;
                Bir istekte bulunursanız, size yanıt vermek için bir ayımız var. Bu haklardan herhangi birini kullanmak
                isterseniz, lütfen bizimle iletişime geçin.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Kullanıcılarımızın Kişisel Bilgilerini satmıyoruz.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Bu haklar hakkında daha fazla bilgi için lütfen bizimle iletişime geçin.
            </p>
        </div>
        <div class="p-col-12">
            <h2><b>Kaliforniya Çevrimiçi Gizliliği Koruma Yasası (CalOPPA)</b></h2>
            <p>
                &emsp;&emsp;&emsp;
                CalOPPA, topladığımız Kişisel Bilgi kategorilerini ve bunları nasıl kullandığımızı, Kişisel Bilgileri
                topladığımız kaynak kategorilerini ve bunları paylaştığımız üçüncü tarafları yukarıda açıkladığımız
                şekilde açıklamamızı gerektirir.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                CalOPPA kullanıcıları aşağıdaki haklara sahiptir:
            </p>
            <ul>
                <li class="p-m-2">Bilme ve Erişim Hakkı. Aşağıdakilerle ilgili doğrulanabilir bir bilgi talebi
                    gönderebilirsiniz: (1) topladığımız, kullandığımız veya paylaştığımız Kişisel Bilgi kategorileri;
                    (2) Kişisel Bilgi kategorilerinin tarafımızca toplanma veya kullanılma amaçları; (3) Kişisel
                    Bilgileri topladığımız kaynak kategorileri; ve (4) hakkınızda topladığımız belirli Kişisel Bilgi
                    parçaları.</li>
                <li class="p-m-2">Eşit Hizmet Hakkı. Gizlilik haklarınızı kullanmanız durumunda size karşı ayrımcılık
                    yapmayacağız.</li>
                <li class="p-m-2">Silme Hakkı. Hesabınızı kapatmak için doğrulanabilir bir talep gönderebilirsiniz ve
                    hakkınızda topladığımız Kişisel Bilgileri sileceğiz.</li>
                <li class="p-m-2">Bir tüketicinin kişisel verilerini satan bir işletmenin, tüketicinin kişisel
                    verilerini satmamasını talep etme hakkı.</li>
            </ul>
            <p>
                &emsp;&emsp;&emsp;
                Bir istekte bulunursanız, size yanıt vermek için bir ayımız var. Bu haklardan herhangi birini kullanmak
                isterseniz, lütfen bizimle iletişime geçin.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Kullanıcılarımızın Kişisel Bilgilerini satmıyoruz.
            </p>
            <p>
                &emsp;&emsp;&emsp;
                Bu haklar hakkında daha fazla bilgi için lütfen bizimle iletişime geçin.
            </p>
        </div>
        <div class="p-col-12">
            <h1><b>Bize Ulaşın</b></h1>
            <p>
                &emsp;&emsp;&emsp;
                Herhangi bir sorunuz varsa bizimle iletişime geçmekten çekinmeyin.
            </p>
            <ul>
                <li class="p-m-2">E-posta yoluyla: info@gelsinteknik.com</li>
                <li class="p-m-2">Bu Bağlantı Üzerinden: www.gelsinteknik.com</li>
            </ul>
        </div>

        <div class="p-col-12 p-d-flex p-jc-center">
            <Button label="Okudum, Anladım, Onaylıyorum" severity="danger" outlined @click="OnayButton()" />
        </div>
    </div>

</template>
<script>
export default {
    props:{
        OnayDurum : Boolean
    },
    data() {
        return {}
    },
    methods: {
        OnayButton() {
            this.$emit("get-onay-data", true);
        }
    },
    components: {
    }
}
</script>
<style></style>